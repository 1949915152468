

import { Link } from "react-router-dom";
import logo from "../assets/img/logo/Final_CRDS Logo_v2.png";
import StyledNavbar from "./StyledNavbar";


function Header() {
  return (
    <>
      <section id="topbar" className="d-flex align-items-center">
        <div className="container d-flex justify-content-center justify-content-md-between">
          <div className="contact-info d-flex align-items-center">
            <img
              src={logo}
              width="110"
              height="60"
              className="d-inline-block align-top"
              alt=" logo"
            />
           
              <Link style={{ paddingLeft: "5px" }} className="navLink" to="/">
                <b>  Center for Regional Development Studies(CRDS)</b>
                 <br />
            
               <b>  Bangladesh University of Engineering and Technology (BUET)</b>
              </Link>
     
          </div>
        </div>
      </section>
    <StyledNavbar />
    </>
  );
}

export default Header;
