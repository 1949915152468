import { newsList } from "../../data";

const NewsList = () => {
  return (
    <div className="sidebar">
      <b style={{fontSize: "28px",color: "brown"}}> News and Events</b>
      <div className="sidebar-item recent-posts">
        {newsList.map((news) => {
          const { id, image, title, link, date } = news;
          return (
            <div key={id} className="post-item clearfix">
              <img src={image} alt={title} />
              <h5>
                {/* <Link to={`/news/${id}`} style={{ color: "#0f5132" }}>
                  {title.substring(0, 50)}...
                </Link> */}
                <a
                  href={link}
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: "#0f5132" }}
                >
                  {title.substring(0, 50)}...
                </a>
              </h5>
              <time dateTime="2020-01-01">{date}</time>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default NewsList;
