import { Link } from "react-router-dom";
import image1 from "../../assets/img/Dr. Afsana Haque.jpg";
import Intro from "../Intro";
import NewsList from "./NewsList";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const NewsAndEvent = () => {
  return (
    <section id="testimonials" className="testimonials">
    
		<Row>
        <Col xs={12} md={8}>
		 <Intro></Intro>
		 </Col>
		 <Col xs={6} md={4}>
		  <NewsList />
                 
                    <Link to="/allNews">See all</Link>
					    </Col>
              </Row>
           <div style={{ textAlign: "left" }} className="container">
        <div style={{ textAlign: "left" }} className="d-flex justify-content-center">         
         
           
            <br />
            
             
             
			   <Container>
			 
                <div style={{ textAlign: "center",fontSize: "28px",color: "brown"}}>  <b> Message from the Director</b> </div><br />
                 
          
             <Row>
        <Col xs={5} > <Card   style={{ boxShadow: "5px 5px 15px rgba(0, 0, 0, 0.3)",border:"2px solid white" ,  padding: "5px" }} > 
		             <Card.Body>
                  
                          <img
                            src={image1}
                            alt="name"
                            className="person-director"
                          />
                        
                       
                          
                            <h6 style={{color: "brown"}}>
                              Professor Afsana Haque, PhD <br />
                              <small>Director</small>
                            </h6>
                            <p style={{color: "brown"}}>
                              <b>
                                Center for Regional Development Studies (CRDS)
                                <br />
                                Bangladesh University of Engineering &
                                Technology (BUET)
                              </b>
                            </p>
				
				         </Card.Body>
                          </Card></Col>
        <Col  > <Card  style={{ height: "340px", boxShadow: "5px 5px 15px rgba(0, 0, 0, 0.3)",border:"2px solid white" ,  padding: "5px"}} >
		           <Card.Body>
				    <Card.Text align="justify">
                        Greetings from Center for Regional Development Studies
                        (CRDS), BUET. Research works from this platform focus on
                        designing and implementing innovative and pragmatic
                        solutions to local, regional, national challenges
                        considering socio-political, cultural and economic
                        contexts at global and local scale. We are engaged in
                        research, learning and training that transforms the
                        knowledge, action and leadership needed for more
                        equitable and sustainable development. We work in
                        partnership with wide range of government and
                        non-government organizations from national and global
                        level. We involve academics, policy makers and civil
                        societies to view the challenges from diversified
                        lenses. Our committed team comprised of dedicated and
                        proficient professionals are eager to contribute in
                        research fields towards sustainable development at local
                        and global scale. As Director of the Center, I am
                        honored to lead this team.
						</Card.Text>
                       </Card.Body>
					
					   </Card></Col>
      
      </Row>
            </Container>
           
        </div>
      </div>
    </section>
  );
};
export default NewsAndEvent;
